<template>
    <div class="contentBorder_container" :style="getBorder()">
        {{content}}
        <div class="contentBorder_icon_container">
            <div class="contentBorder_iconBlock" :style="getBackground()">
                <div class="contentBorder_point" :style="getBorderPoint()"></div>
                <div class="contentBorder_icon">
                    <img :src="require(`@/assets/images/meeting/${iconName}`)" />
                </div>
                <div class="contentBorder_point" :style="getBorderPoint()"></div>
            </div>            
        </div>
    </div>
</template>
<script>
export default {
    name:"contentBorder",
    props:{
        iconName:{
            type:String,
            default:""
        },
        content:{
            type:String,
            default:""
        },
        project:{
            type:String,
            default:""
        },
    },
    data(){
        return {
            data:{
                
            }
        }
    },
    methods:{
        getBorder(){
            let style = ""
            switch (this.project) {
                case "mutai":
                    style = "border: 1px solid #80b25f;"
                    break;
                case "pubeixi":
                    style = "border: 1px solid #004890;"
                    break;
                default:
                    style = "border: 1px solid #9d004c;"
                    break;
            }
          return style;
        },
        getBorderPoint(){
            let style = ""
            switch (this.project) {
                case "mutai":
                    style = "background-color: #80b25f;"
                    break;
                case "pubeixi":
                    style = "background-color: #004890;"
                    break;
                default:
                    style = "background-color: #9d004c;"
                    break;
            }
          return style;
        },
        getBackground(){
            let style = ""
            switch (this.project) {
                case "mutai":
                    style = "background: linear-gradient(#f7fbee,#f1f9e2);";
                    break;
                case "pubeixi":
                    style = "background: linear-gradient(#f6fafe,#eff8fe);";
                    break;
                default:
                    style = "background:linear-gradient(#fcf8fa,#fcf5f8);";
                    break;
            }
          return style;
        }
    }
}
</script>
<style>
.contentBorder_container{
    position: relative;
    width: 100%;
    border-radius: 1vw;
    border: 1px solid #9d004c;
    padding: 10.4vw 3.2vw;
}
.contentBorder_icon_container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.contentBorder_iconBlock{
    position: absolute;
    top: -6.6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(252, 245, 248 0);
    z-index: 100;
    width: 22.8vw;
    background:linear-gradient(#fcf8fa,#fcf5f8);
}
.contentBorder_point{
    background-color: #9d004c;
    width: 1.6vw;
    height: 1.6vw;
    border-radius: 0.8vw;
}
.contentBorder_icon{
    height: 13vw;
    width: 14.4vw;
    display: flex;
    justify-content: center;
}
.contentBorder_icon img{
    height: 100%;
    width: auto;
}
</style>