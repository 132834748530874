<template>
    <div class="showSystemCommonTemplate_container" :class="{'showSystemCommonTemplate_container_only_background':isOnlyTitle()}">
        <div class="showSystemCommonTemplate_title" v-if="hasTitle()" :class="isOnlyTitle()?'showSystemCommonTemplate_only_title':getNotOnlyTitleStyle()">{{this.pageItem.content[0].class_content}}</div>
        <div class="showSystemCommonTemplate_content" :style="getContentBackground()" :class="!hasImgs()?'showSystemCommonTemplate_content_noImg':''" v-if="hasContent() && !hasImgs()">
            <ContentBorder :content="this.pageItem.content[1].class_content" :project="getProject()" :iconName="getIconImg()"></ContentBorder>
        </div>
        <!-- <div class="showSystemCommonTemplate_content showSystemCommonTemplate_content_hasContentAndImgs" v-if="!hasTitle()&&hasContent()&&hasImgs()">
            <contentBorderTow :content="this.pageItem.content[1].class_content" iconName="content_four.png"></contentBorderTow>
        </div> -->
        <div class="showSystemCommonTemplate_content showSystemCommonTemplate_content_hasAll" :style="getContentBackground()" v-if="hasTitle()&&hasContent()&&hasImgs()">
            {{this.pageItem.content[1].class_content}}
        </div>
        <div class="showSystemCommonTemplate_images" v-if="hasImgs()">
            <displayImages :images="imgList"></displayImages>
        </div>
        <div class="global_statement_item">
            <div class="global_statement">{{$config.statement}}</div>
        </div>
    </div>
</template>
<script>
import ContentBorder from '@/components/unit/border/contentBorder'
// import contentBorderTow from '@/components/unit/border/contentBorderTow'
import displayImages from '@/components/unit/displayImages'
export default {
    name:"showSystemCommonTemplate",
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        meetInfo:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            title:"",
            imgList:[],
            iconList:['content_one.png','content_tow.png','content_three.png',],
            data:{
                
            }
        }
    },
    created(){
        this.getImages()
    },
    mounted(){

    },
    components:{
        ContentBorder,
        // contentBorderTow,
        displayImages
    },
    methods:{
        getProject(){
            let key = this.meetInfo?.main_color || "9e004b"
            return this.$config.theme[key].name;
        },
        getNotOnlyTitleStyle(){
           let project = this.getProject();
           let cls = "";
           switch (project) {
            case "pubeixi":
                cls = "pubeixi_showSystemCommonTemplate_not_only_title"
                break;
            default:
                cls = "showSystemCommonTemplate_not_only_title"
                break;
           }
           return cls;
        },
        getContentBackground()
        {
           let project = this.getProject();
           let Background = "";
           switch (project) {
            case "pubeixi":
                Background = "background:linear-gradient(#fff,#daeefc);"
                break;
            default:
                Background = "background:linear-gradient(#fff,#f6e7ee);"
                break;
           }
           return Background;
        },
        hasTitle(){
            return !!(this.pageItem.content[0].class_content)
        },
        hasContent(){
            return !!(this.pageItem.content[1].class_content)
        },
        hasImgs(){
            return !!(this.pageItem.content[2].class_content)
        },
        isOnlyTitle(){
            return this.pageItem.content[1].class_content||this.pageItem.content[2].class_content?false:true
        },
        getIconImg(){
            let prefix = this.getProject();
            let icon = this.iconList[this.pageItem.systemIndex-1]??"content_one.png";
            return prefix ? `${prefix}_${icon}` : icon;
        },
        getImages(){
            this.imgList = this.pageItem.content[2].class_content.split(",")
        }
    }
}
</script>
<style>
.showSystemCommonTemplate_container{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.showSystemCommonTemplate_container_only_background{
    background: #434343;
}
.showSystemCommonTemplate_title{
    width: 100%;
}
.showSystemCommonTemplate_not_only_title{
    flex: 16.4vw 0 0;
    font-size:4.2vw;
    color: #fff;
    display: flex;
    align-items: center;
    padding:0 5.6vw;
    background:linear-gradient(#9e004b,#cb6e9b) ;
}
.pubeixi_showSystemCommonTemplate_not_only_title{
    flex: 16.4vw 0 0;
    font-size:4.2vw;
    color: #fff;
    display: flex;
    align-items: center;
    padding:0 5.6vw;
    background:linear-gradient(#004890,#4f7db5) ;
}
.showSystemCommonTemplate_only_title{
    font-size: 4.8vw;
    font-weight: 600;
    background: #fff;
    flex: 27vw 0 0;
    color: #990041;
    display: flex;
    justify-content: center;
    align-items: center;
}
.showSystemCommonTemplate_content{
    width: 100%;
    word-break: break-all;
    white-space: pre-wrap;
    padding:5vw;
    /* background:linear-gradient(#fff,#f6e7ee); */
}
.showSystemCommonTemplate_content_hasAll{
    flex: 20.8vw 0 0;
    padding: 0 6vw;
    display: flex;
    align-items: center;
    background:#fff
}
.showSystemCommonTemplate_content_noImg{
    flex: 1 0 0;
    display: flex;
    align-items: center;
    padding: 0 6vw;
}
/* .showSystemCommonTemplate_content_hasContentAndImgs{
    flex: 39.3vw 0 0;
} */
.showSystemCommonTemplate_images{
    flex:1 0 0;
}
</style>